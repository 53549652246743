import React from "react"
import SlideToggle from "react-slide-toggle"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"
import { Container, Row, Col } from 'react-bootstrap'

import Layout from "../templates/Page"
import Footer from "../components/ContentBuilder/Footer"
import Header from "../components/ContentBuilder/Header"
import CustomDesign from "../components/ContentBuilder/SwiperList/CustomDesign"
import Dropdown from "../components/ContentBuilder/Dropdown"
import CategoryDropdown from "../components/ContentBuilder/CategoryDropdown"
import Subscribe from "../components/Footer/subscribe"

const BrowserLandPage = () => {
  return (
    <Layout hideHeader>
      <Header isLogin />
      <Container>
        <div className="d-none d-sm-flex justify-content-between align-items-center wrap-section mt-30 mb-30 border-bottom-cell">
          <div className="product-name-title">WILLOWDALE 1</div>
          <div className="d-flex align-items-center">
            <div className="w-100 mr-4">Add to board</div>
            <img src="/img/hearth.svg" />
          </div>
        </div>
        <div className="wrap-section mt-3 mb-3 home-detail-title">
          Browse Land
        </div>

        <div className="explore-functional">
          <Dropdown title="SORT" />
          <CategoryDropdown title="CATEGORY" />

          <div className="menu-margin search-box">
            <input className="filter-text" placeholder="SEARCH" />
            <div className="filter-icon">
              <img src="/img/explore/search.svg" />
            </div>
          </div>
        </div>

        <Row className="wrap-section mt-5">
          <Col md={3}>
            <div className="filter-modal-container w-100p">
                <div className="w-100p mt-15">
                  <div className="d-sm-flex align-items-center mt-15 mb-15 border-bottom-cell">
                    <div className="browser-title">My address</div>
                  </div>
                  <div className="browser-input">
                    <input placeholder="ENTER HERE" />
                  </div>
                </div>
                <div className="w-100p mt-15 mb-15">
                  <div className="browser-sub-title mt-15 pointer">
                    Change Address
                  </div>
                </div>

                <SlideToggle>
                  {({ toggle, setCollapsibleElement }) => (
                    <div className="widget widget-collapsible">
                      <h3 className="widget-title">
                        <div
                          className="wiget-title-name"
                          onClick={e => {
                            toggle(e)
                            e.preventDefault()
                          }}
                        >
                          LAND SIZE
                        </div>
                      </h3>
                      <div className="widget-body" ref={setCollapsibleElement}>
                        <div className="input-range-container">
                          <InputRange
                            formatLabel={value => `$${value}`}
                            maxValue={1000}
                            minValue={0}
                            step={100}
                            value={10}
                            onChange={value => {
                              // setValue(value)
                              // props.filterPrice(value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </SlideToggle>
                <SlideToggle>
                  {({ toggle, setCollapsibleElement }) => (
                    <div className="widget widget-collapsible">
                      <h3 className="widget-title">
                        <div
                          className="wiget-title-name"
                          onClick={e => {
                            toggle(e)
                            e.preventDefault()
                          }}
                        >
                          LAND AREA
                        </div>
                      </h3>
                      <div className="widget-body" ref={setCollapsibleElement}>
                        <div className="input-range-container">
                          <InputRange
                            formatLabel={value => `$${value}`}
                            maxValue={1000}
                            minValue={0}
                            step={100}
                            value={10}
                            onChange={value => {
                              // setValue(value)
                              // props.filterPrice(value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </SlideToggle>
                <SlideToggle>
                  {({ toggle, setCollapsibleElement }) => (
                    <div className="widget widget-collapsible">
                      <h3 className="widget-title">
                        <div
                          className="wiget-title-name"
                          onClick={e => {
                            toggle(e)
                            e.preventDefault()
                          }}
                        >
                          PRICE
                        </div>
                      </h3>
                      <div className="widget-body" ref={setCollapsibleElement}>
                        <div className="input-range-container">
                          <InputRange
                            formatLabel={value => `$${value}`}
                            maxValue={1000}
                            minValue={0}
                            step={100}
                            value={10}
                            onChange={value => {
                              // setValue(value)
                              // props.filterPrice(value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </SlideToggle>
              </div>
          </Col>
        
          <Col md={9}>
            <Row>
              <Col md={6}>
                <div className="product-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="border-bottom pb-2 pt-2">Address</div>
                  <div className="border-bottom pb-2 pt-2">
                    Land Area (m2) width x length
                  </div>
                  <div className="border-bottom pb-2 pt-2">Price</div>
                  <div className="map"></div>
                </div>
              
              </Col>

              <Col md={6}>
                <div className="product-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="border-bottom pb-2 pt-2">Address</div>
                  <div className="border-bottom pb-2 pt-2">
                    Land Area (m2) width x length
                  </div>
                  <div className="border-bottom pb-2 pt-2">Price</div>
                  <div className="map"></div>
                </div>
              
              </Col>

              <Col md={6}>
                <div className="product-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="border-bottom pb-2 pt-2">Address</div>
                  <div className="border-bottom pb-2 pt-2">
                    Land Area (m2) width x length
                  </div>
                  <div className="border-bottom pb-2 pt-2">Price</div>
                  <div className="map"></div>
                </div>
              
              </Col>

              <Col md={6}>
                <div className="product-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="border-bottom pb-2 pt-2">Address</div>
                  <div className="border-bottom pb-2 pt-2">
                    Land Area (m2) width x length
                  </div>
                  <div className="border-bottom pb-2 pt-2">Price</div>
                  <div className="map"></div>
                </div>
              
              </Col>
            </Row>
            <div className="d-flex justify-content-center">
              <button className="btn border text-link mb-5">
                LOAD MORE
              </button>
            </div>
          </Col>
        </Row>

      </Container>
      <Subscribe></Subscribe>
      
    </Layout>
  )
}

export default BrowserLandPage
